import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  Paper,
  Button,
  IconButton,
  MenuItem,
  TextField,
  Modal,
  Slider,
  Menu,
  Snackbar,
} from "@mui/material";
import ReactPanZoom from "react-image-pan-zoom-rotate";
import { GoReport } from "react-icons/go";
import { RiCloseFill } from "react-icons/ri";
import { FaFlag } from "react-icons/fa";
import { FaArrowLeftLong } from "react-icons/fa6";
import { examService, questionService, resultService } from "../../service";
import { useParams } from "react-router-dom";
import { ImageBaseUrl } from "../../config/api-config";
import { useNavigate } from "react-router-dom";
import Scoreboard from "./Scoreboard";

const Rapid = () => {
  let { examId, resultId } = useParams();
  const navigate = useNavigate();

  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [displayTimer] = useState(true);
  const [exitConfirmationOpen, setExitConfirmationOpen] = useState(false);
  const handleExitConfirmationOpen = () => {
    setExitConfirmationOpen(true);
  };

  const handleExitConfirmationClose = () => {
    setExitConfirmationOpen(false);
  };
  const handleExitConfirmationYes = () => {
    // User confirmed, navigate back to the previous page (dashboard)
    navigate(-1);
  };

  const handleExitConfirmationNo = () => {
    // User canceled, close the modal
    setExitConfirmationOpen(false);
  };

  //   // Add more questions as needed
  // ]);
  const [questionData, setQuestionsData] = useState({});
  const [questions, setQuestions] = useState([]);
  const [contrast, setContrast] = useState(1); // Define setContrast function
  const [brightness, setBrightness] = useState(100);
  const [timer, setTimer] = useState(1800); // 30 minutes in seconds
  const [answers, setAnswers] = useState([]);
  const [currentAnswer, setCurrentAnswer] = useState({
    isFlagged: false,
    radAnswer: "",
    radReason: "",
    reportReason: "",
    questionId: "",
  });

  const [scoreBoardVisible, setScoreBoardVisible] = useState(false);
  const [isFullScreen, setIsFullScreen] = useState(false);
  // const [selectedPaperIndex, setSelectedPaperIndex] = useState(0);
  const [menuAnchor, setMenuAnchor] = useState(null);
  const [buttonColor, setButtonColor] = useState("#000000");
  // const [modalDropdownOpen, setModalDropdownOpen] = useState(false);
  const [showReportReply, setShowReportReply] = useState(false);
  const [isRefresh, setIsRefresh] = useState(false);
  const [loading, setLoading] = useState(false);
  const handleContrastChange = (event, newValue) => {
    setContrast(newValue);
  };

  const handleBrightnessChange = (event, newValue) => {
    setBrightness(newValue);
  };
  const handleQuestionSubmit = (currentAnswerData,finished = false) => {
    if (currentAnswerData.radAnswer === "") delete currentAnswerData.radAnswer;
    if (currentAnswerData.radReason === "") delete currentAnswerData.radReason;
    // delete currentAnswerData.reportReason;
    // resultId,radAnswer ,radReason,isFlagged, reportReason
    let data = {
      ...currentAnswerData,
      resultId,
    };
    resultService.upsertAnswer(data).then((res) => {
      setLoading(false)
      if (finished) {
        setScoreBoardVisible(true);
      }
    });
  };
  // for getting answers data when refresh
  useEffect(() => {
    resultService.getAllAnswers({ resultId }).then((res) => {
      if(res.status){

        if (res.data?.isExamFinished) {
          setScoreBoardVisible(true);
      } else {
        const answersData = res.data?.answers;

        const finalData = answersData.map((data) => {
          if (!data.radAnswer) data.radAnswer = "";
          if (!data.radReason) data.radReason = "";
          if (!data.reportReason) data.reportReason = "";
          if (!data.isFlagged) data.isFlagged = false;
          return data;
        });
        setAnswers(finalData);
        //for set first question data
        let questionId = questions?.[0]?.questions?.[currentQuestion];
        let changeExitIndex = finalData.findIndex(
          (data) => data.questionId === questionId
        );
        if (changeExitIndex !== -1) {
          setCurrentAnswer(finalData[changeExitIndex]);
        }
      }
    }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  //for setting first data answer when refresh
  useEffect(() => {
    let questionId = questions?.[0]?.questions?.[currentQuestion];
    let changeExitIndex = answers.findIndex(
      (data) => data.questionId === questionId
    );
    if (changeExitIndex !== -1) {
      setCurrentAnswer(answers[changeExitIndex]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isRefresh]);

  // for question listing
  useEffect(() => {
    examService.getOneExam(examId, resultId).then((res) => {
      if (res.status) {
        setQuestions(res.data);
        // console.log(res.data,'examData')
        if (res.data?.[0]?.timer) {
          setTimer(res.data?.[0]?.timer);
        }
        questionService
          .getOneQuestion(res.data?.[0]?.questions?.[currentQuestion])
          .then((res) => {
            if (res.status) {
              setQuestionsData(res.data);
              setCurrentAnswer((prev) => ({
                ...prev,
                questionId: res.data?._id,
              }));
              setIsRefresh(!isRefresh);
            }
          });
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  //  for question data
  useEffect(() => {
    if (questions?.length) {
      questionService
        .getOneQuestion(questions?.[0]?.questions?.[currentQuestion])
        .then((res) => {
          if (res.status) {
            setQuestionsData(res.data);
          }
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentQuestion]);

  const handleDrawerItemClick = (questionId, index) => {
    questionService.getOneQuestion(questionId).then((res) => {
      if (res.status) {
        setQuestionsData(res.data);
      }
    });
    const prevQuestionId = questions?.[0]?.questions?.[currentQuestion];
    const nextValue = index;
    const nextQuestionId = questions?.[0]?.questions?.[nextValue];
    questionChangeHelper(prevQuestionId, nextQuestionId);
    setCurrentQuestion(index);
  };

  ///
  const questionChangeHelper = (prevQuestionId, changeQuestionId) => {
    let answerArray = answers;
    currentAnswer.questionId = prevQuestionId;
    //api function for save  the user's answer to database
    if (
      currentAnswer.radAnswer ||
      currentAnswer.radReason ||
      currentAnswer.reportReason
    ) {
      handleQuestionSubmit(currentAnswer);
    }
    //prev data upsert
    let prevExitIndex = answerArray.findIndex(
      (data) => data.questionId === prevQuestionId
    );
    if (prevExitIndex !== -1) {
      answerArray[prevExitIndex] = currentAnswer;
    } else {
      answerArray.push(currentAnswer);
    }
    setAnswers(answerArray);
    //change data  initialized
    let changeExitIndex = answerArray.findIndex(
      (data) => data.questionId === changeQuestionId
    );
    if (changeExitIndex !== -1) {
      let exitAnswerArray = answerArray[changeExitIndex];
      if (!exitAnswerArray.radAnswer) exitAnswerArray.radAnswer = "";
      if (!exitAnswerArray.radReason) exitAnswerArray.radReason = "";
      if (!exitAnswerArray.reportReason) exitAnswerArray.reportReason = "";
      setCurrentAnswer(exitAnswerArray);
    } else {
      setCurrentAnswer(() => ({
        radAnswer: "",
        radReason: "",
        reportReason: "",
        isFlagged: false,
        questionId: changeQuestionId,
      }));
    }
  };
  const handlePreviousQuestion = () => {
    const prevQuestionId = questions?.[0]?.questions?.[currentQuestion];
    const nextValue = Math.max(currentQuestion - 1, 0);
    const nextQuestionId = questions?.[0]?.questions?.[nextValue];
    questionChangeHelper(prevQuestionId, nextQuestionId);
    setCurrentQuestion((prevQuestion) => Math.max(prevQuestion - 1, 0));
  };

  const handleNextQuestion = () => {
    const prevQuestionId = questions?.[0]?.questions?.[currentQuestion];
    const nextValue = Math.min(
      currentQuestion + 1,
      questions?.[0]?.questions?.length - 1
    );
    const nextQuestionId = questions?.[0]?.questions?.[nextValue];
    questionChangeHelper(prevQuestionId, nextQuestionId);
    setCurrentQuestion((prevQuestion) =>
      Math.min(prevQuestion + 1, questions?.[0]?.questions?.length - 1)
    );
  };

  const handleFlagSubmit = (questionId) => {
    resultService
      .flagQuestionToggle({ questionId, resultId })
      .then((res) => {});
  };

  const handleEndExam = () => {
    const questionId = questions?.[0]?.questions?.[currentQuestion];
    currentAnswer.questionId = questionId;
    currentAnswer.isExamFinished = true;
    setLoading(true);
    handleQuestionSubmit(currentAnswer,true);

    // setScoreBoardVisible(true);
    // setSelectedPaperIndex(0);
  };
  ///
  const handleFullScreenClick = () => {
    setIsFullScreen(true);
  };

  const handleCloseFullScreen = () => {
    setIsFullScreen(false);
  };

  const handleMenuOpen = (event) => {
    setMenuAnchor(event.currentTarget);
    setButtonColor("#EB568F");
    // setModalDropdownOpen(true); // Set the dropdown state to true
  };

  const handleMenuClose = () => {
    setMenuAnchor(null);
    setButtonColor("#000000");
    // setModalDropdownOpen(false); // Set the dropdown state to false
  };
  const filterStyles = {
    filter: `contrast(${contrast}) brightness(${brightness}%)`,
  };

  const [reportModalOpen, setReportModalOpen] = useState(false);

  const handleReportIconClick = () => {
    setReportModalOpen(true);
  };

  const handleReportModalClose = () => {
    setReportModalOpen(false);
  };

  const handleReportSubmit = () => {
    const data = {
      questionId: currentAnswer.questionId,
      reportReason: currentAnswer.reportReason,
      resultId,
    };
    resultService.reportQuestion(data).then((res) => {
      setShowReportReply(true);
    });
    // Close the report modal
    setReportModalOpen(false);
  };
  const handleReportReplyClose = () => {
    // Close the reply message box
    setShowReportReply(false);
  };
  //  for time changing
  useEffect(() => {
    let interval;
    if (displayTimer) {
      interval = setInterval(() => {
        setTimer((prevTimer) => (prevTimer > 0 ? prevTimer - 1 : 0));
      }, 1000);
    }

    return () => {
      clearInterval(interval);
    };
  }, [displayTimer]);

  return (
    <div>
      {scoreBoardVisible ? (
        <Scoreboard
          questions={questions?.[0]?.questions || []}
          answers={answers}
          resultId={resultId}
          examId={examId}
          examName={questions?.[0]?.examName}
        />
      ) : (
        <div>
          <div>
            <div>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <IconButton sx={{ ml: 2 }} onClick={handleExitConfirmationOpen}>
                  <FaArrowLeftLong style={{ color: "#EB568F" }} />
                </IconButton>

                {/* Exit Confirmation Modal */}
                <Modal
                  open={exitConfirmationOpen}
                  onClose={handleExitConfirmationClose}
                  aria-labelledby="exit-modal-title"
                  aria-describedby="exit-modal-description"
                >
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      minHeight: "100vh",
                    }}
                  >
                    <Paper
                      sx={{
                        width: "600px",
                        padding: "20px",
                        borderRadius: "15px",
                      }}
                    >
                      <Typography
                        variant="h5"
                        id="exit-modal-title"
                        gutterBottom
                      >
                        Are you sure you want to exit the mock?
                      </Typography>
                      <Typography id="exit-modal-description" sx={{ mb: 2 }}>
                        Your progress will not be saved.
                      </Typography>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "end",
                        }}
                      >
                        <Button
                          variant="outlined"
                          color="error" // Use the desired color
                          sx={{ borderRadius: "8px", mr: 2 }}
                          onClick={handleExitConfirmationNo}
                        >
                          No
                        </Button>
                        <Button
                          variant="contained"
                          color="primary" // Use the desired color
                          sx={{ borderRadius: "8px" }}
                          onClick={handleExitConfirmationYes}
                        >
                          Yes
                        </Button>
                      </Box>
                    </Paper>
                  </Box>
                </Modal>
                <Typography sx={{ml:4, fontWeight:"bold"}}>{questions?.[0]?.examName}</Typography>
                {questions?.[0]?.questions?.length ? (
                  <>
                    <Button
                      variant="outlined"
                      sx={{
                        borderRadius: "7px",
                        margin: "0 auto",
                        display: "block",
                      }}
                    >
                      {timer > 0 ? (
                        <Typography sx={{ textTransform: "none" }}>
                          Ends in{" "}
                          <span style={{ color: "red" }}>
                            {" "}
                            {Math.floor(timer / 60)}:
                            {timer % 60 < 10 ? "0" : ""}
                            {timer % 60}
                          </span>
                        </Typography>
                      ) : (
                        <Typography
                          variant="h6"
                          sx={{ color: "red", textAlign: "center" }}
                        >
                          Time's Up!
                        </Typography>
                      )}
                    </Button>

                    <Button
                      variant="contained"
                      onClick={handleEndExam}
                      disabled={loading}
                      sx={{
                        mr: 6,
                        width: "8%",
                        background: "#EB568F",
                        "&:hover": {
                          backgroundColor: "#8890A7",
                        },
                      }}
                    >
                      End Exam
                    </Button>
                  </>
                ) : (
                  ""
                )}
              </Box>
            </div>

            <div>
              <Paper
                elevation={0}
                sx={{
                  padding: "20px",
                  pb: "0px",

                  width: "98%",
                  display: "flex", // Add this line
                  flexWrap: "wrap", // Add this line
                }}
              >
                {questions?.[0]?.questions?.map((id, index) => (
                  <Paper
                    key={index}
                    sx={{
                      p: 1,
                      backgroundColor:
                        currentQuestion === index ? "#EB568F" : "inherit",
                      color: currentQuestion === index ? "white" : "inherit",
                      cursor: "pointer",
                      "&:hover": {
                        backgroundColor: "black",
                        color: "white",
                      },
                      width: "3.2%", // Adjust the width to fit 25 numbers in one row
                      boxSizing: "border-box",
                      textAlign: "center",
                      margin: "0 10px 10px 0",
                    }}
                    onClick={() => handleDrawerItemClick(id, index)}
                  >
                    {index + 1}
                  </Paper>
                ))}
              </Paper>
            </div>
            <div>
              {questions?.[0]?.questions?.length ? (
                <Paper
                  elevation={0}
                  sx={{
                    padding: "20px",
                    mt: 2,
                    width: "98%",
                  }}
                >
                  {/* <Typography variant="h6" sx={{ mb: 2 }}>
                      Question {currentQuestion + 1}:
                    </Typography> */}
                  <Box elevation={20} sx={{ display: "flex", mt: 1 }}>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "30px",
                        width: "50%",
                        pr:{
                          xs: 1,
                          sm: 1,
                          md: 10,
                          lg: 25,                            
                        }
                      }}
                    >
                      {/* element of header */}
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          marginBottom: "10px",
                          rowGap: "30px",
                          
                        }}
                      >
                        <Typography variant="h6" sx={{ mb: 2 }}>
                          Question {currentQuestion + 1}:
                        </Typography>
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                            marginBottom: "10px",
                            marginLeft: "30px",
                          }}
                        >
                          <IconButton
                            onClick={() => {
                              setCurrentAnswer((prev) => ({
                                ...prev,
                                isFlagged: !prev.isFlagged,
                              }));
                              handleFlagSubmit(questionData?._id);
                            }}
                            sx={{
                              color: currentAnswer.isFlagged
                                ? "#EB568F"
                                : "inherit",
                            }}
                          >
                            <FaFlag />
                            <span
                              style={{ fontSize: "14px", marginLeft: "10px" }}
                            >
                              Flag
                            </span>
                          </IconButton>
                          {/* Report Icon */}
                          <IconButton
                            onClick={handleReportIconClick}
                            sx={{ color: "black" }}
                          >
                            {/* Replace the following icon with the desired "Report" icon */}
                            <GoReport />{" "}
                            <span
                              style={{ fontSize: "14px", marginLeft: "10px" }}
                            >
                              Report
                            </span>
                          </IconButton>

                          {/* Report Modal */}
                          <Modal
                            open={reportModalOpen}
                            onClose={handleReportModalClose}
                            aria-labelledby="report-modal"
                            aria-describedby="report-modal-description"
                          >
                            <Paper
                              elevation={5}
                              sx={{
                                p: 3,
                                width: 400,
                                position: "absolute",
                                top: "50%",
                                left: "50%",
                                transform: "translate(-50%, -50%)",
                              }}
                            >
                              <Typography variant="h5" gutterBottom>
                                Report this Question?
                              </Typography>
                              <TextField
                                label="Reason"
                                multiline
                                rows={4}
                                variant="outlined"
                                fullWidth
                                value={currentAnswer.reportReason}
                                onChange={(e) =>
                                  setCurrentAnswer((prev) => ({
                                    ...prev,
                                    reportReason: e.target.value,
                                  }))
                                }
                                sx={{ mb: 2 }}
                              />
                              <Button
                                variant="contained"
                                color="primary"
                                onClick={handleReportSubmit}
                              >
                                Submit
                              </Button>
                            </Paper>
                          </Modal>
                          <Snackbar
                            sx={{ ml: 30 }}
                            open={showReportReply}
                            autoHideDuration={3000} // Adjust the duration as needed
                            onClose={handleReportReplyClose}
                            message="Updated your feedback successfully" // The message you want to display
                          />

                          <Button
                            onClick={handleMenuOpen}
                            sx={{
                              position: "relative",
                              left: "1rem",
                              color: buttonColor,
                            }}
                          >
                            Image Settings
                          </Button>
                          <Menu
                            anchorEl={menuAnchor}
                            open={Boolean(menuAnchor)}
                            onClose={handleMenuClose}
                          >
                            <MenuItem>
                              <Paper elevation={3} sx={{ p: 2, mb: 2 }}>
                                <div
                                  style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    alignItems: "flex-start",
                                  }}
                                >
                                  <div
                                    style={{
                                      cursor: "pointer",
                                      color: "blue",
                                      marginBottom: "10px",
                                    }}
                                    onClick={handleFullScreenClick}
                                  >
                                    Fullscreen image
                                  </div>
                                  <div>
                                    <Typography variant="body1">
                                      Contrast:
                                    </Typography>
                                    <Slider
                                      value={contrast}
                                      onChange={handleContrastChange}
                                      min={0.1}
                                      max={3}
                                      step={0.1}
                                      sx={{
                                        width: "50px",
                                        marginBottom: "10px",
                                      }}
                                    />
                                  </div>
                                  <div>
                                    <Typography variant="body1">
                                      Brightness:
                                    </Typography>
                                    <Slider
                                      value={brightness}
                                      onChange={handleBrightnessChange}
                                      min={50}
                                      max={200}
                                      step={1}
                                      sx={{ width: "50px" }}
                                    />
                                  </div>
                                </div>
                              </Paper>
                            </MenuItem>
                          </Menu>
                        </div>
                      </div>
                      {/*  */}
                      <Typography variant="body1">
                        {/* {questions[currentQuestion].text} */}
                        {questionData?.question}
                      </Typography>
                      <TextField
                        select
                        label="Select Normal/Abnormal"
                        value={currentAnswer.radAnswer}
                        onChange={(e) =>
                          setCurrentAnswer((prev) => ({
                            ...prev,
                            radAnswer: e.target.value,
                          }))
                        }
                      >
                        <MenuItem value="" disabled>
                          Select Normal/Abnormal
                        </MenuItem>
                        <MenuItem value="Normal">Normal</MenuItem>
                        <MenuItem value="Abnormal">Abnormal</MenuItem>
                      </TextField>

                      {currentAnswer.radAnswer === "Abnormal" && (
                        <TextField
                          label="Reason for Abnormal"
                          value={currentAnswer.radReason}
                          onChange={(e) =>
                            setCurrentAnswer((prev) => ({
                              ...prev,
                              radReason: e.target.value,
                            }))
                          }
                          multiline
                          rows={3}
                        />
                      )}

                      <div sx={{ mt: 2 }}>
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={handlePreviousQuestion}
                          sx={{
                            width: "30%",
                            display: currentQuestion === 0 ? "none" : "block",
                            "&:hover": {
                              backgroundColor: "#EB568F",
                            },
                          }}
                        >
                          Previous
                        </Button>

                        <Button
                          variant="contained"
                          color="primary"
                          onClick={handleNextQuestion}
                          sx={{
                            width: "30%",
                            mt: 2,
                            display:
                              currentQuestion ===
                              questions?.[0]?.questions?.length - 1
                                ? "none"
                                : "block",
                            "&:hover": {
                              backgroundColor: "#EB568F",
                            },
                          }}
                        >
                          Next
                        </Button>

                        {currentQuestion ===
                          questions?.[0]?.questions?.length - 1 && (
                          <Button
                            variant="contained"
                            color="primary"
                            onClick={handleEndExam}
                            disabled={loading}
                            sx={{
                              width: "30%",
                              mt: 2,
                              "&:hover": {
                                backgroundColor: "#EB568F",
                              },
                            }}
                          >
                            Submit
                          </Button>
                        )}
                      </div>
                    </Box>

                    <Box
                      component="div"
                      sx={{
                        width: "50%",
                        height: "75vh",
                        position: "relative",
                        overflow: "hidden",
                      }}
                    >
                      <div
                        className="pan-zoom-container"
                        style={{
                          height: "100%",
                          width: "100%",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          filter: `contrast(${contrast}) brightness(${brightness}%)`,
                        }}
                      >
                        <ReactPanZoom
                          image={`${ImageBaseUrl}${questionData?.image?.[0]}`}
                          alt={`Question ${currentQuestion + 1}`}
                          // crossorigin="anonymous"
                        />
                      </div>
                    </Box>
                  </Box>
                </Paper>
              ) : null}
            </div>
          </div>

          <Modal
            open={isFullScreen}
            onClose={handleCloseFullScreen}
            aria-labelledby="fullscreen-modal"
            aria-describedby="fullscreen-modal-description"
          >
            <div>
              <Menu
                anchorEl={menuAnchor}
                open={Boolean(menuAnchor)}
                onClose={handleMenuClose}
                sx={{ ml: 50 }}
              >
                <MenuItem>
                  <Paper elevation={3} sx={{ p: 2, mb: 2 }}>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "flex-start",
                      }}
                    >
                      <div
                        style={{
                          cursor: "pointer",
                          color: "blue",
                          marginBottom: "10px",
                        }}
                        onClick={handleFullScreenClick}
                      >
                        Fullscreen image
                      </div>
                      <div>
                        <Typography variant="body1">Contrast:</Typography>
                        <Slider
                          value={contrast}
                          onChange={handleContrastChange}
                          min={0.1}
                          max={3}
                          step={0.1}
                          sx={{ width: "50px", marginBottom: "10px" }}
                        />
                      </div>
                      <div>
                        <Typography variant="body1">Brightness:</Typography>
                        <Slider
                          value={brightness}
                          onChange={handleBrightnessChange}
                          min={50}
                          max={200}
                          step={1}
                          sx={{ width: "50px" }}
                        />
                      </div>
                    </div>
                  </Paper>
                </MenuItem>
              </Menu>
              <div
                style={{
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                  width: "30%",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <ReactPanZoom
                  image={`${ImageBaseUrl}${questionData?.image?.[0]}`}
                  alt={`Question ${currentQuestion + 1}`}
                  className="pan-zoom-container"
                  style={{
                    ...filterStyles,
                    maxWidth: "100%",
                    maxHeight: "80vh",
                  }}
                  crossorigin="anonymous"
                />

                <IconButton
                  onClick={handleCloseFullScreen}
                  sx={{
                    position: "relative",
                    bottom: "36rem",
                    left: "20rem",
                    zIndex: 9999,
                    alignSelf: "flex-end",
                    bgcolor: "#EB568F",
                  }}
                >
                  <RiCloseFill style={{ color: "white" }} />
                </IconButton>
              </div>
            </div>
          </Modal>
        </div>
      )}
    </div>
  );
};

export default Rapid;
